import React from "react";
import Tilt from "react-parallax-tilt";
import ToiletPaper from "./toilet_paper.jpg";

function App() {
	return (
		<div className="container">
			<h1>Best Toilet Paper in the World</h1>
			<Tilt>
				<img src={ToiletPaper} width="400" alt="Toilet Paper" />
			</Tilt>
			<h2>What is the best toilet paper in the world?</h2>
			<p>
				According to Google, it's the Pakistan Flag. It's time to prove them
				wrong. <br /> <br />
				There are many different types of toilet paper and many different brands
				that could be considered the best toilet paper in the world. However
				only one can be given that crown. Let's find out who's the best by
				sending in your nominated toilet paper name to{" "}
				<a href="mailto:whatsthebesttoiletpaper@gmail.com">
					whatsthebesttoiletpaper@gmail.com
				</a>
				. <br />
				<br /> The winner will be added to the site.
			</p>
		</div>
	);
}

export default App;
